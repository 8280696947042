import React from "react";
import { useState, useEffect } from "react";
import logo from "../images/ghmclogo 1.png";
import flowerlogo from "../images/Group 1320.png";
import puppiesmap from "../images/Group 105.png";
import vector from "../images/Mask Group 36.png";
import blurvector from "../images/Mask Group 37.png";
import blurvector2 from "../images/Mask Group 38.png";
import "../StoreLocator/Storelocator.css";
import Path from "../images/Path 65.png";
import indicator from "../images/Layer 2.png";
import Maps from "./Maps";
import Footer from "../Footer/Footer";
import Scroll from "../Backtotop/Scroll";

export const Storelocator = () => {
  const [data, setData] = useState();
  const [storetype, setStoretype] = useState();
  const [citytype, setCitytype] = useState();
  const [list, setlist] = useState();
  const [filterdata, setFilterData] = useState();

  const handleChange = async (e) => {
    setlist(e.target.value);
    e.stopPropagation();
    {
      const response = await fetch(
        `https://stage.content.betterworldforpets.in/api/get/store-list/${
          citytype && citytype[0].cityId
        }/${e.target.value}?_format=json`
      ).then((response) => response.json());

      setFilterData(response);
    }
  };
  const handleChange1 = async (e) => {
    setlist(e.target.value);
    e.stopPropagation();
    {
      const response = await fetch(
        `https://stage.content.betterworldforpets.in/api/get/store-list/all/all?_format=json`)
        .then((response) => response.json());
      setFilterData(response);
    }
  };
  const Storecontentapi = async () => {
    const response = await fetch(
      `https://stage.content.betterworldforpets.in/api/get/store/info?_format=json`
    ).then((response) => response.json());

    setData(response);
  };

  const Storelistapi = async () => {
    const response = await fetch(
      `https://stage.content.betterworldforpets.in/api/get/store-list/all/all?_format=json`
    ).then((response) => response.json());

    setFilterData(response);
  };
  const Storetypeapi = async () => {
    const response = await fetch(
      `https://stage.content.betterworldforpets.in/api/get/store-type-list?_format=json`
    ).then((response) => response.json());

    setStoretype(response);
  };
  const Citytypeapi = async () => {
    const response = await fetch(
      `https://stage.content.betterworldforpets.in/api/get/city-list?_format=json`
    ).then((response) => response.json());

    setCitytype(response);
  };
  useEffect(() => {
    Storecontentapi();
    Storelistapi();
    Storetypeapi();
    Citytypeapi();
  }, []);

  return (
    <>
      <section>
        <div className="image-overlap">
          <img src={puppiesmap} alt="" className="" />
        </div>
        <div className="vector-img">
          <img src={vector} alt="" />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="ghmc-logo">
                <img src={logo} alt="ghmc logo" />
              </div>
              <div className="f-logo-bg">
                <img src={flowerlogo} alt="ghmc logo" />
                <img src={flowerlogo} alt="ghmc logo" className="flower-logo" />
              </div>
              <div className="text-content">
                <h2>Find</h2>
                {/* <hr className="text-border"></hr> */}
                <h1
                  dangerouslySetInnerHTML={{ __html: data && data[0].title }}
                ></h1>
                <p
                  dangerouslySetInnerHTML={{ __html: data && data[0].body }}
                ></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="blurvector-img">
          <img src={blurvector} alt="" className="" />
          <img src={blurvector2} alt="" className="blur-img" />
        </div>
        <div className="container store">
          <div className="store-text">
            <h3
              dangerouslySetInnerHTML={{ __html: data && data[0].subtitle }}
            ></h3>
          </div>
          <div className="row">
            <div className="col-md-7">
              <div className="store-input">
                <input
                  type="text"
                  id="fname"
                  name="firstname"
                  placeholder="Please Enter Location"
                  disabled={true}
                  defaultValue={citytype && citytype[0].cityName}
                />
              </div>
              <div className="store-radio mt-4">
              <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          // value={e.storeTypeId}
                           onChange={handleChange1}
                           defaultChecked
                        />
                        <label class="form-check-label" for="inlineRadio1">
                          All Services
                        </label>
                      </div>
                {storetype &&
                  storetype.map((e) => {
                    return (
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="inlineRadioOptions"
                          id="inlineRadio1"
                          value={e.storeTypeId}
                          onChange={handleChange}
                        />
                        <label class="form-check-label" for="inlineRadio1">
                          {e.storeTypeName}
                        </label>
                      </div>
                    );
                  })}
              </div>
              <div className="map mt-5">{<Maps filterdata={filterdata} />}</div>
            </div>
            <div className="col-md-5 m6 maps-data-top">
              {filterdata &&
                filterdata.map((e) => {
                  return (
                    <div className="maps-data">
                      <div>
                        <h6>{e.storeName}</h6>
                        <p>
                          {" "}
                          <img src={Path} alt="" className="mr-3" />
                          {e.storeAddress}
                        </p>
                      </div>
                      <div>
                        <a
                          href={`https://maps.google.com/?q=${e.storeAddress}`}
                          // target="_blank"
                          // rel="noreferrer"
                        >
                          <img src={indicator} alt="" className="arrow" />
                        </a>
                        <p>INDICATIONS</p>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </section>      
      <Footer></Footer>
      <Scroll />
    </>
  );
};
