import React from "react";
import { useState, useEffect } from "react";
import logo from "../images/ghmclogo 1.png";
import flowerlogo from "../images/Group 1320.png";
import vector from "../images/Mask Group 36.png";
import groomingimg from "../images/Group 63.png";
import { Trainingcard } from "./Trainingcard";
import singledog from "../images/single-dog.png";
import blurvector from "../images/Mask Group 37.png";
import blurvector2 from "../images/Mask Group 38.png";
import Scroll from "../Backtotop/Scroll";

export const Trainingcategory = () => {
    const [categorydata, setCategoryData] = useState();
    const Param = window.location.search;
    const categoryId = Param.replace("?", "");
  const TrainingCategoryListapi = async () => {
    const response = await fetch(
      `https://stage.content.betterworldforpets.in/api/get/pet-training-list/by-category/${categoryId}/?_format=json&page=0`
    ).then((response) => response.json());

    setCategoryData(response);
  };
  useEffect(() => {
    TrainingCategoryListapi();
  }, []);

  return (
    <>
      <section>
      <div className="image-overlap">
                <img src={groomingimg} alt="" />
              </div>
              <div className="vector-img">
                  <img src={vector} alt="" />
                </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="ghmc-logo">
                <img src={logo} alt="ghmc logo" />
              </div>
              <div className="f-logo-bg">
                <img src={flowerlogo} alt="ghmc logo" />
                <img src={flowerlogo} alt="ghmc logo" />
              </div>
              <div className="text-content">
                <h2>{categorydata && categorydata[0].categoryName} :</h2>
                
                <p>
                  {categorydata && categorydata[0].categoryLongDescription}
                </p>
             
              </div>
            </div>

         
          </div>
        </div>
      </section>
      <section className='grooming-top'>
    <div className="blurvector-img">
                  <img src={ blurvector } alt="" className="blur-img-left" />
                  <img src={ blurvector2 } alt="" className="blur-img" />
                </div>
        <div className="container">
          <div className="gromming-text">
            <h2> {categorydata && categorydata[0].categorySubtitle}</h2>
            <p>
              {" "}
              {categorydata && categorydata[0].categorySubtext}              
            </p>
          </div>
          <div className="row">
            {categorydata &&categorydata.map((e) => {
              return (
                <div className="col-md-4">
                  <Trainingcard
                    articleImageURL={e.articleImageURL}
                    articleName={e.articleName}
                    articleShortDescription={e.articleShortDescription}
                  />
                  <div className="grooming-button">
                      <a
                        href={`${window.location.origin}/Trainingarticle/?${categorydata && categorydata[0].articleId}`}
                      >
                        <button type="button" className="btn readmore-btn">
                          Read More
                        </button>
                      </a>
                    </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <section className="grooming-vets-bg mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="vets-text">
                <h3>Training for Pets</h3>
                <a
                          href={`${window.location.origin}/Storelocator`}
                        >
                <button type="button" className="btn readmore-btn">
                  Show Nearby Vets
                </button> </a>
              </div>
            </div>
            <div className="col-md-6">
              <div className="vets-img">
                <img src={singledog} alt="" className="image-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Scroll/>
    </>
  );
};
