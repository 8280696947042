import React from 'react'
import '../Termandcondition/Terms.css';
import Scroll from "../Backtotop/Scroll";

export const Terms = () => {
  return (
    <>
        <div class="container">
            <h1 class="text-center mb-5 heading">Terms and Conditions</h1>
            <h5 class="text-center side-heading">The pet dog owner shall fulfill the following conditions, otherwise pet dog registration is deemed to be cancelled:
            </h5>
            <ol type="1" class="terms">
                <li>The pet dog registration is deemed to be suspended/ cancelled if any of the applicable terms and conditions are found to be contravened
                    or any situation arises out of this pet dog so as to cause nuisance/ damage/ harm to property or health of the public or
                    if documents/ grounds furnished in the application are found to be false and untrue or if there is a change in GHMC policy of
                    pet dog registration.
                    </li>
                <li>The owner shall not prohibit/ obstruct the authorized officer of the Corporation to inspect the premises under question at any time and without notice.
                </li>
                <li>The owner of the registration pet dog shall keep all the times a copy of the pet dog registration along with him while taking his pet outside his home
                    and the owner shall on demand produce the pet dog registration to the authorized officer.
                    </li>
                    <li> The owner of the dog shall not let loose his pet dog in any public street or public place and always take out the dog secured with a leash/ chain.
                    </li>
                    <li>No one being the owner or person in-charge of the dog shall allow any ferocious dog to be at large without being muzzled in any public street or public place.
                    </li>
                    <li> The owner shall be responsible for all the actions of his dog including causing nuisance or harm to any person.
                    </li>
                    <li> The owner shall not allow his dog to defecate on any public place and shall be responsible for cleaning the area if the dog does so.
                    </li>
                    <li>The owner shall provide adequate food and shelter to the dog and shall not cause any cruelty to the dog.
                    </li>
                    <li>The owner shall be responsible for good health and timely Anti Rabies Vaccination & other prophylactic vaccinations to his pet dog.
                    </li>
                    <li>In case of change of ownership, fresh registration is required.</li>
                    <li>This registration is granted only as per the information provided by the owner and there shall be no legal obligation on this Corporation.
                    </li>
                    <li> The owner shall comply with the provision of any of law for the time being in force.
                    </li>
                    <li class="red-cl">The pet dog registration number once generated shall remain unchanged throughout the life of the dog.
                    </li>
                    <li>This registration is only for raising/ possessing/ owning the pet dog. It does not confer any power of permission for either temporary or
                        permanent exemption from the provisions of any other section of GHMC Act, bye-laws or rules in force.
                        (Issued as per delegation of powers Under Section 119 of GHMC Act, 1955)</li>
              </ol> 
              
              <Scroll/>
        </div>
    </>
  )
}
