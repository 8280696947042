import React from 'react'


export const Healthcard = (props) => {
  return (
    <>
            <div className='grooming-cards'>
              <img src={props.imageURL}></img>
              <h4>{props.title}</h4>
              <p dangerouslySetInnerHTML={{__html: props.shortDescription}}></p>
              
            </div>

     
    </>
  )
}
