import React from "react";
import { useState, useEffect } from "react";
import flowerlogo from "../images/Group 1320.png";
import vector from "../images/Mask Group 36.png";
import logo from "../images/ghmclogo 1.png";
import "../Gromming puppy/Gpuppy.css";
import Footer from "../Footer/Footer";
import Scroll from "../Backtotop/Scroll";

export const Trainingarticle = () => {
  const [data, setData] = useState();
  const Param = window.location.search;
  const Article = Param.replace("?", "");
  const Trainingarticleapi = async () => {
    const response = await fetch(
        `https://stage.content.betterworldforpets.in/api/get/pet-training-article/${Article}?_format=json`      
    ).then((response) => response.json());
    setData(response);
  };
  useEffect(() => {
    Trainingarticleapi();
  }, []);

  return (
    <>
      <section>
      <div className="image-overlap">
                <img src={data && data[0].articleImageURL} alt="" className="" />
              </div>
              <div className="vector-img">
                  <img src={vector} alt="" />
                </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="ghmc-logo">
                <img src={logo} alt="ghmc logo" />
              </div>
              <div className="f-logo-bg">
                <img src={flowerlogo} alt="ghmc logo" />
                <img src={flowerlogo} alt="ghmc logo" />
              </div>
              <div className="text-content">
                <h2
                  dangerouslySetInnerHTML={{ __html: data && data[0].articleName }}
                ></h2>
                {/* <hr className="text-border"></hr> */}
                {/* <h1>Puppies and Dogs</h1> */}
                <div
                  classname="data"
                  dangerouslySetInnerHTML={{
                    __html: data && data[0].articleDescription,
                  }}
                ></div>
             
              </div>
            </div>

        
        
          </div>
        </div>
      </section>
      <Scroll/>
      <Footer></Footer>
    </>
  )
}