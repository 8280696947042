import React from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import "../StoreLocator/Storelocator.css";

const mapStyles = {
  width: "60%",
  height: "70%",
};

function Maps(props) {
  const latlong = props.filterdata;
  const storelanglat =
    latlong &&
    latlong.map((e) => {
      return e.storeGeoLocation;
    });
  const finallat =
    storelanglat &&
    storelanglat.map((e) => {
      return eval("(" + e + ")");
    });

  function displayMarkers() {
    return (
      finallat &&
      finallat.map((store, index) => {
        return (
          <Marker
            key={index}
            id={index}
            position={{
              lat: store.latitude,
              lng: store.longitude,
            }}
            onClick={() => {}}
          />
        );
      })
    );
  }

  return (
    <div>
      <Map
        className="googlemaps"
        google={props.google}
        zoom={10}
        style={mapStyles}
        initialCenter={{ lat: 17.385, lng: 78.4867 }}
      >
        {displayMarkers()}
      </Map>
    </div>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBdpx2H4NqJ1fESr1MrtSvRsiZEeblhS7Y",
})(Maps);
