import React from "react";
import address from "../images/Group 82.png";
import phone from "../images/Group 83.png";
import email from "../images/Group 84.png";
import footerdivider from "../images/Group 1945.png";
import "./footer.css";
import footerlogo from "../images/ghmclogo 1.png";
import footerline from "../images/Line 101.png";

const Footer = () => {
  return (
    <>
      <footer class="footer-bg mt-5">
        <div class="container">
          <div class="row">
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-4 col-4 footer-width">
                  <img src={address} alt="" />
                </div>
                <div class="col-md-7 col-7 mt-4 footer-address">
                  <p>
                    MARS International India Pvt. Ltd., SURVEY NO. 56/64,
                    GAURARAM VILLAGE, BEHIND JANATHA HOTEL, WARGAL MOMNDAL,
                    MEDAK DISTRICT, Medak, Telangana, 502279
                  </p>
                </div>
                <div class="col-md-1 mt-2 d-none d-md-block footerdivider-1">
                  <img src={footerdivider} alt="" />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-4 col-4 footer-width">
                  <img src={phone} alt="" />
                </div>
                <div class="col-md-7 col-7 mt-4 footer-address">
                  <p>
                    Tel: 1800 889 21 21 (Toll free) <br />
                    Landline: +91 8454350080/81 (Normal call charges apply)
                  </p>
                </div>
                <div class="col-md-1 d-none d-md-block mt-2 footerdivider-2">
                  <img src={footerdivider} alt="" />
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-4 col-4 footer-width">
                  <img src={email} alt="" />
                </div>
                <div class="col-md-7 col-7 mt-4 footer-address">
                  <p>contact@in.mars.com</p>
                </div>
              </div>
            </div>
            {/* <div class="col-md-1">
            <img src={footerdivider} alt=""/>
          </div> */}
          </div>
          <div className="row">
            <div className="col-md-3 col-3">
              <div>
                <img src={footerlogo} alt="" />
              </div>
            </div>
            <div className="col-md-6 col-6 footer-line">
              <img src={footerline} alt="" />
            </div>
            <div className="col-md-3 col-12 copyright">
              <div>
                <p>Copyright © 2021, Mars Pet Care All Rights Reserved</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
