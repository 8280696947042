import {
  BrowserRouter as Router,
  Routes,
  Route,
  BrowserRouter,
} from "react-router-dom";
import Feedingtable from "./component/Feeding/Feedingtable";
import { Qrcode } from "./component/Qrcode/Qrcode";
import { Storelocator } from "./component/StoreLocator/Storelocator";
import { Dogtracker } from "./component/Tracking/Dogtracker";
import { Terms } from "./component/Termandcondition/Terms";
import { Training } from "./component/Pet-training/Training";
import { Grooming } from "./component/Grooming/Grooming";
import { Community } from "./component/Community/Community";
import { Gpuppy } from "./component/Gromming puppy/Gpuppy";
import Healthcare from "./component/Healthcare/Healthcare";
import { Healtharticle } from "./component/Healthcare/Healtharticle";
import { Trainingarticle } from "./component/Pet-training/Trainingarticle";
import { Trainingcategory } from "./component/Pet-training/Trainingcategory";
import { Communityarticle } from "./component/Community/Communityarticle";

const App = () => (
  <BrowserRouter>
    <Routes>
    <Route exact path="/Storelocator" element={<Storelocator />}></Route>
    <Route exact path="/Communityarticle" element={<Communityarticle />}></Route>
      <Route exact path="/Seasonalhealthcare" element={<Healthcare />}></Route>
      <Route exact path="/Gpuppy" element={<Gpuppy />}></Route>
      <Route exact path="/Community" element={<Community />}></Route>
      {/* <Route exact path="/Training" element={<Training />}></Route> */}
      <Route exact path="/Dogtracker" element={<Dogtracker />}></Route>
      <Route exact path="/Feedingtable" element={<Feedingtable />}></Route>
      <Route exact path="/Healtharticle" element={<Healtharticle />}></Route>
      <Route exact path="/Trainingcategory" element={<Trainingcategory />}></Route>
      <Route exact path="/Trainingarticle" element={<Trainingarticle />}></Route>
      <Route exact path="/Qrcode" element={<Qrcode />}></Route>
      <Route exact path="/Terms" element={<Terms />}></Route>
      <Route exact path="/Grooming" element={<Training />}></Route>
      <Route exact path="/" element={<p>Home page</p>}></Route>
    </Routes>
  </BrowserRouter>
);

export default App;
