import React from 'react'


export const Trainingcard = (props) => {
  return (
    <>
            <div className='grooming-cards'>
              <img src={props.articleImageURL}></img>
              <h4>{props.articleName}</h4>
              <p dangerouslySetInnerHTML={{__html: props.articleShortDescription}}></p>
              
            </div>

     
    </>
  )
}
