import React from "react";
import { useState, useEffect } from "react";
import "../Qrcode/Qrcode.css";
import logo from "../images/ghmclogo 1.png";
import flowerlogo from "../images/Group 1320.png";
import vector from "../images/Mask Group 36.png";
import qrimage from "../images/Group 110.png";
import "../Tracking/Dogtracker.css";
import googleplay from "../images/google-play.png";
import Footer from "../Footer/Footer";
import apple from "../images/applestore.png";
import Scroll from "../Backtotop/Scroll";
import  blurvector from "../images/Mask Group 37.png"
import  blurvector2 from "../images/Mask Group 38.png"


export const Qrcode = () => {
  const [parentnName, setParentName] = useState("");
  const [petName, setPetName] = useState("");
  const [error, setError] = useState(null);
  const [personName, setPersonName] = useState("");
  const [emailName, setEmailName] = useState("");
  const [textareaName, setTextareaName] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [data, setData] = useState();
  const [message, setMessage] = useState("");

  // Function to collect data
  const getApiData = async () => {
    const petNumber = window.location.search;
    const response = await fetch(
      `https://stage.api.betterworldforpets.in/api/AddPet/GetDefaultDataForQRScan${petNumber}`
    ).then((response) => response.json());

    setData(response);
  };

  useEffect(() => {
    getApiData();
  }, []);

  const handleChange = (e) => {
    setParentName(e.target.value);
  };
  const handlemobileChange = (e) => {
    setMobileNumber(e.target.value);
  };
  const handlepetChange = (e) => {
    setPetName(e.target.value);
  };
  const handlepersonChange = (e) => {
    setPersonName(e.target.value);
  };
  function isValidEmail(emailName) {
    return /\S+@\S+\.\S+/.test(emailName);
  }

  const handleemailChange = (event) => {
    if (!isValidEmail(event.target.value)) {
      setError("Email is invalid");
    } else {
      setError(null);
    }

    setEmailName(event.target.value);
  };

  const handletextareaChange = (e) => {
    setTextareaName(e.target.value);
  };

  const resetForm = () => {
    setPersonName("");
    setEmailName("");
    setTextareaName("");
    setError("");
    setMobileNumber("");
  };

  let handleSubmit = async (e) => {
    e && e.preventDefault();
    try {
      let res = await fetch(
        "https://stage.api.betterworldforpets.in/api/MailNotification/SendMailForScannedPet",
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            FromEmail: emailName,
            MailBody: textareaName,
            Subject: `Reported found/lost pet : ${data.PetName}`,
            ToEmail: data.UserEmail,
            PetId: data.PetId,
            UserId: data.UserId,
            FromUserContact: mobileNumber,
          }),
        }
      );
      let resJson = await res.json();
      if (res.status === 200) {
        setPersonName("");
        setEmailName("");
        setTextareaName("");
        setError("");
        setMobileNumber("");
        setMessage("form has been submitted successfully");
      } else {
        setMessage("Some error occured");
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
     <section>
      <div className="image-overlap">
                <img src={qrimage} alt="" className="" />
              </div>
              <div className="vector-img">
                  <img src={vector} alt="" />
                </div>
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="ghmc-logo">
                <img src={logo} alt="ghmc logo" />
              </div>
              <div className="f-logo-bg">
                <img src={flowerlogo} alt="ghmc logo" />
                <img src={flowerlogo} alt="ghmc logo" className='flower-logo' />
              </div>
              <div className="text-content">
                <h2>Pet Parent </h2>
                {/* /* <hr className="text-border"></hr> */}
                <h1>Details</h1>
              
              </div>
            </div>

           
          </div>
        </div>
      </section>
      <div className="blurvector-img">
                  <img src={ blurvector } alt="" className="blur-img-left  qr-blur" />
                  <img src={ blurvector2 } alt="" className="blur-img  qr-blur" />
                </div>
      <section className="qr-form">
        <div className="container">
          <form className="qr-main-form" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <label className="qr-label" for="fname">
                  Pet Parent Name
                </label>

                <input
                  type="text"
                  id="petparentname"
                  name="petparentname"
                  className="qr-type"
                  value={data && data.UserName}
                  required
                  disabled={true}
                  onChange={(e) => {
                    handleChange(e);
                  }}
                />
              </div>
              <div className="col-md-6">
                <label className="qr-label" for="fname">
                  Pet Name
                </label>

                <input
                  type="text"
                  id="paretname"
                  name="parentname"
                  className="qr-type"
                  value={data && data.PetName}
                  required
                  onChange={(e) => {
                    handlepetChange(e);
                  }}
                  disabled={true}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label className="qr-label" for="fname">
                  Person Name
                </label>

                <input
                  type="text"
                  id="personname"
                  name="personname"
                  className="qr-type"
                  placeholder="Enter Your Name"
                  value={personName}
                  required
                  onChange={(e) => {
                    handlepersonChange(e);
                  }}
                />
              </div>
              <div className="col-md-6">
                <label className="qr-label" for="fname">
                  Personal Email
                </label>

                <input
                  type="email"
                  id="emailname"
                  name="emailname"
                  className="qr-type"
                  placeholder="Enter Your Email ID"
                  value={emailName}
                  required
                  onChange={(e) => {
                    handleemailChange(e);
                  }}
                />
                {error && <span style={{ color: "red" }}>{error}</span>}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label className="qr-label" for="fname">
                  Mobile Number
                </label>
                <input
                  type="text"
                  id="mobilenumber"
                  name="mobile"
                  className="qr-type"
                  placeholder="Mobile Number"
                  value={mobileNumber}
                  required
                  onChange={(e) => {
                    handlemobileChange(e);
                  }}
                />
              </div>{" "}
            </div>
            <div>
              <label className="qr-label" for="fname">
                Comments
              </label>
              <div className="textarea-form">
                <textarea
                  name="comment"
                  form="usrform"
                  placeholder="Enter Your Comments"
                  value={textareaName}
                  required
                  onChange={(e) => {
                    handletextareaChange(e);
                  }}
                />
              </div>
            </div>

            <div className="qr-button">
              <button
                type="button"
                className="btn submit-btn"
                onClick={() => handleSubmit()}
              >
                Submit
              </button>
              <button
                type="button"
                className="btn reset-btn"
                onClick={() => resetForm()}
              >
                Reset
              </button>
            </div>
          </form>
          <div className="qr-form-bg-left"></div>
          <div className="qr-form-bg-right"></div>
          <div className="download-apps">
            <h3>Download Our App</h3>
            <div className="ml-5 apps">
              <a href="https://play.google.com/store/apps/details?id=com.mars.petRegistration&pli=1">
                {" "}
                <img src={googleplay} alt="" />{" "}
              </a>
              <a href="https://apps.apple.com/in/app/pawgenz/id1671792942?platform=iphone">
                {" "}
                <img src={apple} alt="" />{" "}
              </a>
            </div>
          </div>
        </div>
      </section>
      
      <Footer></Footer>
      <Scroll/>
    </>
  );
};
